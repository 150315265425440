.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

.eye-password {
  position: absolute;
  top: 29%;
  right: 10%;
}

.eye-password:hover {
  color: #06d6a0;
  cursor: pointer;
}

.aside-menu {
  background-color: #2b2d42;
}

.brand-roypet {
  background-color: #001e60 !important;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 25px;
  height: 65px;
}

.brand-roydermal {
  background-color: #da291c !important;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 25px;
  height: 65px;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #8d99ae;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
  background-color: #8d99ae;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active {
  background-color: #8d99ae;
  transition: background-color 0.3s;
}
.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #092327;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #edf2f4;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-icon {
  color: #092327;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background-color: #8d99ae;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-text {
  color: #2b2d42;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #2b2d42;
}

.aside-arrow-btn {
  background-color: #8d99ae;
}

.aside-arrow-btn:hover {
  background-color: #edf2f4;
}

.brand-dark .header-mobile-roydermal {
  background-color: #da291c;
}

.brand-dark .header-mobile-roypet {
  background-color: #001e60;
}

.brand-dark .header-mobile .burger-icon span::before,
.brand-dark .header-mobile .burger-icon span::after {
  background-color: #fff;
}

.brand-dark .header-mobile .burger-icon span {
  background-color: #fff;
}

.brand-dark .header-mobile .btn .svg-icon svg g [fill],
.btn:not(:disabled):not(.disabled) {
  transition: fill 0.3s ease;
  fill: #fff;
}

.brand-dark .brand .btn .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #fff;
}

.brand-dark .brand .btn .svg-icon svg g :hover [fill] {
  transition: fill 0.3s ease;
  fill: #2b2d42;
}

.aside-roypet {
  background-color: #2b2d42;
  border-right: 1px solid #2b2d42;
}

.aside-roydermal {
  background-color: #2b2d42;
  border-right: 1px solid #2b2d42;
}

.fc .fc-button-primary {
  color: #fff;
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #2b2d42;
  background-color: #2b2d42;
  background-color: var(--fc-button-bg-color, #2b2d42);
  border-color: #2b2d42;
  border-color: #2b2d42;
  border-color: var(--fc-button-border-color, #2C3
  E50);
}
.fc .fc-button-primary:disabled {
  color: #fff;
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #2C3E50;
  background-color: #2C3E50;
  background-color: var(--fc-button-bg-color, #2C3E50);
  border-color: #2C3E50;
  border-color: #2C3E50;
  border-color: var(--fc-button-border-color, #2C3
  E50);
}

